import React, {useState} from 'react'
import styled from 'styled-components'
import Link from '../Link'
import { useLayout } from '../../hooks/resourceHooks'
import * as colors from '../../config/theme/colors'
import media, {useBreakpoint} from '../../config/theme/media'
import Accordion from '../Accordion'

const NavItemLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;
  display: relative;

  &:hover, &:focus {
    color: ${colors.hyperlink()};
    border-bottom: 3px solid;
  }

  &:active {
    background-color: ${colors.primary()};
    color: black;
    border-bottom: 3px solid;
  }

  ${media.lessThan('md')`
    margin: 10px;

    &:hover {
      border-bottom: 0;
    }
  `}
`
const ArrowDown = styled.div`
width: 0;
height: 0;
border-left: 5px solid transparent;
border-right: 5px solid transparent;
align-self: center;
border-top: 6px solid ${colors.hyperlink()};
margin-left: 8px;
`

const ArrowRight = styled.div`
width: 0; 
height: 0; 
margin-left:10px;
margin-top: auto;
margin-bottom: auto;
vertical-align: middle;
border-top: 5px solid transparent;
border-bottom: 5px solid transparent;
display: inline-block;
border-left: 5px solid white;
${media.greaterThan('md')`
  border-left: 5px solid gray;
`}
`
const SubpageNavList = styled.ul`
  list-style-type: none;  
  margin: 0;
  padding: 0;
  ${media.lessThan('md')`
  padding: 10;
  margin: 10;
  `}
  display: block;
  position: absolute;
  top: 100%;  
`
const SubpageNavLink = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  padding: 10px 10px;

  ${media.lessThan('md')`
    font-size: 14px;
    display: flex; 
    align-items: center;  
    padding: 0px;
    padding-left: 14px;
    color: ${(props) => props.depth > 0 ? 'gray' : 'black'};
    min-height: ${(props) => props.depth < 1 && '45px'};
    text-decoration: none;
    border: none;
  `}

  ${media.greaterThan('md')`
  font-size: 16px;
  display: none;
  top: 100%;
  left: 0;
  z-index: 10;
  white-space: nowrap;
  background-color: white;
  border-bottom: 1px solid gray;
  color: gray;
  &:hover ${ArrowRight}, &:focus-within ${ArrowRight} {
    border-left-color: ${colors.hyperlink()}; 
  }
  &:hover, &.current, &:focus, &:active {
    display: flex;
    flex-direction: row;
    color: ${colors.hyperlink()};
    background-color: #ececec;
    border-bottom: 2px solid ${colors.hyperlink()};
  }
  `}
`
const NavListItemContainer = styled.div`
  display:flex;
  flex-directiion: row;
  padding: 0 15px;
`
const NavListItem = styled.li`
  list-style-type: none;  
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-height: 40px;
  line-height: 1.2;
  padding: 10px 10px;
  float: left;

  ${media.greaterThan('md')`
    display: inline-block;
    &:hover ${SubpageNavLink}, &:focus-within ${SubpageNavLink} {
      display: flex;
      flex-direction: row;
    }

    &:hover > ${NavItemLink}, &:focus-within > ${NavItemLink} {
      color: ${colors.hyperlink()};
    }
  `}
  &.current ${SubpageNavList},
  &:focus ${SubpageNavList},
  &:active ${SubpageNavList},
  &:focus-within ${SubpageNavList} {
    margin-top: 4px; // subpage menu sits below active underlined element
  }
  &.current, &:focus, &:active {
    border-bottom: 4px solid ${colors.hyperlink()};

    a:hover {
      border-bottom: 0;
    }
  }
  
  ${media.lessThan('md')`
    float: none;
    display: block;
    padding-left: 4px;

    &.current &:focus, &:active{
      border-bottom: 0px ;
      padding-left: 0;
      border-left: 4px solid ${colors.hyperlink()};
    }

  `}
`
const SubpageNavItem = styled.li`
  list-style-type: none; 
  margin-top: 0;
  padding: 0;
  position: relative; 
`
const SubpageSubNav = styled.div`
  display: block;
  ${media.greaterThan('md')`
  display: none; /* Hidden by default */
  position: absolute;
  top: 0;
  left: 100%;  
  background-color: white;
  padding: 0;
  margin: 0;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  ${SubpageNavItem}:hover &,
  ${SubpageNavItem}:focus-within & {
    display: block;  
  }`}

`
const SubpageSubNavList = styled.ul`
  display: flex;  
  flex-direction: column;  
  padding: 0;  
  margin: 0;  
  list-style-type: none;  
  
  & > li {
    margin: 5px 0;  
  }

  ${media.lessThan('md')`
    display:block;
    width: 100%;
  `}
`

const Navigation = ({prefix, closeNav}) => {
  const layout = useLayout()
  const mainNavigation = _.get(layout, 'mainNavigation.fields.pages')
  const pagePath = process.browser && window.location.pathname
  const { lt } = useBreakpoint()
  const isMobile = lt('md')
    // State to track if submenu is expanded
    const [expandedItem, setExpandedItem] = useState(null)

    const handleMouseEnter = (itemId) => {
      setExpandedItem(itemId)
    }
  
    const handleMouseLeave = () => {
      setExpandedItem(null)
    }

  const renderSubpages = (subpages, depth = 0, maxDepth = 2) => {
    if (depth >= maxDepth) return null  // Stop rendering if the depth exceeds maxDepth
    if(isMobile) {
      const accordionData = subpages.map((subpage) => {
        const subpageUrlName = _.get(subpage, 'fields.pageUrlName')
        const subpagePageTitle = _.get(subpage, 'fields.pageTitle')
        const subpageExternalUrl = _.get(subpage, 'fields.externalUrl')
        const subpageSubpages = _.get(subpage, 'fields.subpages')

        const subpageLink = (
        <SubpageNavLink onClick={() => closeNav()} depth={depth} href={subpageExternalUrl || `/${subpageUrlName}`}>
          {subpagePageTitle}
        </SubpageNavLink>)

        const subpageSubNavLinks = subpageSubpages && 
        (<SubpageSubNav>
            <SubpageSubNavList>
            {renderSubpages(subpageSubpages, depth + 1, maxDepth)}
            </SubpageSubNavList>
        </SubpageSubNav>)

        return {
          title: subpagePageTitle,
          link: subpageLink,
          content: subpageSubNavLinks || null
        }
      })
      
      return <Accordion accordionData={accordionData} depth={2} headerColor={colors.grey()}/>
    }
    return (
      <SubpageNavList>
        {subpages.map((subpage) => {
          const subpageUrlName = _.get(subpage, 'fields.pageUrlName')
          const subpagePageTitle = _.get(subpage, 'fields.pageTitle')
          const subpageExternalUrl = _.get(subpage, 'fields.externalUrl')
          const subpageSubpages = _.get(subpage, 'fields.subpages')
  
          return (
            <SubpageNavItem key={`HeaderLink_${subpageUrlName}`}>
              <SubpageNavLink depth={depth} href={subpageExternalUrl || `/${subpageUrlName}`}>
                {subpagePageTitle}
                {subpageSubpages && <ArrowRight />}
              </SubpageNavLink>
              {subpageSubpages && (
                <SubpageSubNav>
                  <SubpageSubNavList>
                    {renderSubpages(subpageSubpages, depth + 1, maxDepth)}
                  </SubpageSubNavList>
                </SubpageSubNav>
              )}
            </SubpageNavItem>
          )
        })}
      </SubpageNavList>
    )    
  }

  const mainNavigationLinks = (prefix = 'desktop') => {
    if (isMobile) {
      // Map mainNavigation to accordionData
      const accordionData = mainNavigation.map((link) => {
        const fields = _.get(link, 'fields', {})
        const subpages = _.get(fields, 'subpages')
        const contentTitle = _.get(fields, 'contentTitle') || _.get(fields, 'name')
        const pageUrlName = _.get(fields, 'pageUrlName')
        const externalUrl = _.get(fields, 'url')
        const newWindow = _.get(fields, 'newWindow')
      
        const navLink = (
          <NavItemLink
            href={externalUrl || `/${pageUrlName}`}
            key={`MainNavigation_${pageUrlName}`}
            target={newWindow ? '_blank' : ''}
          >
            {contentTitle}
          </NavItemLink>
        )
      
        const subpageLinks = subpages ? renderSubpages(subpages) : null
      
        return {
          title: contentTitle,
          link: navLink,
          content: subpageLinks || null
        }
      })
        
      return <Accordion accordionData={accordionData} depth={1} headerColor={colors.grey()}/>
    }

    return _.map(mainNavigation, (link) => {
      const fields = _.get(link, 'fields', {})
      const subpages = _.get(fields, 'subpages')
      const fieldId = _.get(link, 'sys.id')
      const publishOnWebsite = _.get(fields, 'publishOnWebsite')
      const contentTitle = _.get(fields, 'contentTitle') || _.get(fields, 'name')
      const pageUrlName = _.get(fields, 'pageUrlName')
      const externalUrl = _.get(fields, 'url')
      const newWindow = _.get(fields, 'newWindow')

      if (!externalUrl && !publishOnWebsite) {
        return null
      }

      return (
        <NavListItemContainer 
        onMouseEnter={() => handleMouseEnter(fieldId)}
        onMouseLeave={handleMouseLeave}>
            <NavListItem 
            aria-expanded={expandedItem === fieldId ? "true" : "false"}
            key={`${prefix}_${fieldId}`}
            className={_.toLower(pagePath) === `/${_.toLower(pageUrlName)}` ? 'current' : ''}>
                <NavItemLink
                  href={externalUrl || `/${pageUrlName}`}
                  key={`MainNavigation_${pageUrlName}`}
                  target={newWindow ? '_blank' : ''}
                >
                  {contentTitle}
                </NavItemLink>
                {subpages && renderSubpages(subpages)}
            </NavListItem>
          {subpages && <ArrowDown/>}
        </NavListItemContainer>
      )
    }
  )}

  return mainNavigationLinks(prefix)

}

export default Navigation